import { defineStore } from 'pinia';

export const useGamesStore = defineStore('gamesStore', () => {
  const supportedCurrencies = ref(null);

  function $reset() {
    supportedCurrencies.value = null;
  }

  return {
    supportedCurrencies,
    $reset,
  };
});

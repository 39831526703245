<template>
  <div
    class="hidden lg:block bg-slate-700 absolute top-14 p-4 rounded-xl animate-slide-down-fade-in lg:w-[296px lg:-right-4"
  >
    <h2 class="text-white text-lg mb-2 leading-tight animate-fade-in">
      What is M.ETH?
    </h2>
    <p class="text-slate-100 text-sm normal-case tracking-normal animate-fade-in">
      M.ETH is a virtual currency you can earn interacting with MetaWin, please head
      over to The Arena Discord to find out more.
    </p>
  </div>
</template>
